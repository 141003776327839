export const languages = [
    {
        code: 'it-IT',
        alternativeCode: 'it',
        label: 'Italiano'
    },
    {
        code: 'en-EN',
        alternativeCode: 'en',
        label: 'English'
    },
    {
        code: 'pt-PT',
        alternativeCode: 'pt',
        label: 'Português'
    },
    {
        code: 'tr-TR',
        alternativeCode: 'tr',
        label: 'Turkish'
    },
    {
        code: 'et-ET',
        alternativeCode: 'et',
        label: 'Eesti keel'
    },
    {
        code: 'ru-RU',
        alternativeCode: 'ru',
        label: 'Ру́сский язы́к'
    },
    {
        code: 'sv-SE',
        alternativeCode: 'sv',
        label: 'Svenska'
    },
    {
        code: 'fi-FI',
        alternativeCode: 'fi',
        label: 'Suomalainen'
    }
]
