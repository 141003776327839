import React, {useContext} from "react";
import {useGetOrganizations, useGetShops, useGetVendors} from "../../../services/ContentManager";
import {deleteElem, put, SHOPS, VENDORS} from "../../../services/Client";
import {SHOP_ROUTE} from "../../../constants/vendors";
import {UserContext} from "../../App";
import CircularLoading from "../../../components/CircularLoading";
import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {isAdmin as getIsAdmin} from "../../../services/PermissionManager";
import ShopList from "../../../components/VendorComponents/ShopsList";
import {generatePublicLink} from "../../../services/couponRedeemManager";


export default function Shops({uid, urlPrefix = ''}) {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const isAdmin = getIsAdmin(useContext(UserContext));

    let {shops, status: statusShops} = useGetShops(uid);
    let {vendors: vendor, status: statusVendor} = useGetVendors(uid);

    if (statusShops === 'loading' || statusVendor === 'loading')
        return <CircularLoading/>

    function onShopDelete(shop) {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        deleteElem(VENDORS + '/' + uid + '/' + SHOPS, {elem: shop.id})
            .then(() => {
                queryClient.invalidateQueries([SHOPS, {vendor: uid}]).then(() => enqueueSnackbar(t('shopDeleted'), {variant: "success"}));

            })
            .catch((e) => {
                enqueueSnackbar(t('shopCantBeDeleted'), {variant: "error"});
                console.log(e)
            })
    }

    function changeIsVisibile(shop) {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        put(SHOPS + '/' + shop.id, {body: {visible: !shop.visible}})
            .then(() => {
                queryClient.invalidateQueries([SHOPS, {vendor: uid}]).then(() => enqueueSnackbar(t('saved'), {variant: "success"}));

            })
            .catch((e) => {
                enqueueSnackbar(t('errorSavingData'), {variant: "error"});
                console.log(e)
            })
    }

    const onShopClick = (shop) => {
        history.push(urlPrefix + SHOP_ROUTE + (shop.id || ''));
    };

    return (
        <ShopList
            shops={shops}
            onShopClick={onShopClick}
            onShopDelete={onShopDelete}
            changeIsVisibile={changeIsVisibile}
            disabled={isAdmin}
        />
    );
}