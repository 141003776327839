import BaseModal from "./BaseModal";
import makeStyles from '@mui/styles/makeStyles';
import React,{useEffect,useState} from "react";
import Grid from "@mui/material/Grid";
import {TextInputContainer} from "../forms/TextInput";
import FormControl from "@mui/material/FormControl";
import {FormControlLabel,FormLabel,Radio,RadioGroup} from "@mui/material";
import FileInput from "../forms/FileInput";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RadioAwardType from "../forms/RadioAwardType";

const useStyles = makeStyles((theme) => ({
        radioLabel : {
            color : theme.palette.primary.light,
            display : 'flex',
            alignContent : "space-between",
            '&.Mui-focused' : {
                color : theme.palette.primary.light
            }
        }
    }
));

export default function RankAwardModal({open, onClose, onSubmit, defaultValues = {}, isEditing}) {

    const {t} = useTranslation();
    let {id} = useParams();
    let [values, setValues] = useState({positionType: 0,type: 0});
    let [error, setError] = useState({});
    let classes = useStyles();

    useEffect(() => {
        let newValues = {...defaultValues};
        newValues["positionType"] = 0;
        if(newValues.type === null || newValues.type === undefined) newValues["type"] = 0;
        if(newValues.type === "MONEY") newValues.type = 0;
        if(newValues.type === "POINTS") newValues.type = 1;

        setValues(newValues);
    }, [open])

    const close = () => {
        setValues({positionType: 0,type: 0})
        setError({})
        onClose();
    }

    const save = () => {
        if(!values.name) {
            setError({name: t('mandatoryField')});
            return;
        }
        if(!isEditing && values.positionType === 0 && !values.position){
            setError({position: t('mandatoryField')});
            return;
        }
        if(!isEditing && values.positionType === 1){
            if(!values.minPosition){
                setError({minPosition: t('mandatoryField')});
                return;
            }
            if(!values.maxPosition){
                setError({maxPosition: t('mandatoryField')});
                return;
            }
            if(parseInt(values.maxPosition) <= parseInt(values.minPosition)){
                setError({maxPosition: t('invalidValue')});
                return;
            }
            if(!values.amount || values.amount < 0){
                setError({amount: t('invalidValue')});
                return;
            }
        }
        if(values.type !== 2 && (!values.value || values.value < 0)){
            setError({value: t('invalidValue')});
            return;
        }
        if(values.type === "MONEY") values.type = 0;
        if(values.type === "POINTS") values.type = 1;
        if (values.positionType === 0) {
            values.minPosition = null;
            values.maxPosition = null;
            values.amount = null;
        } else if (values.positionType === 1) {
            values.position = null;
        }
        onSubmit(values);
        close()
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        newValues[name] = value;
        setError({});
        setValues(newValues);
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}>
            <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>

                    <TextInputContainer xs={12} label={t('name')} value={values.name}
                               type="string" required
                               onTextChange={(value) => {onTextChange(value, "name")}}
                               error={error.name}/>

                {!isEditing &&
                    <>
                        <Grid item xs={12} style={{padding: "16px"}}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" className={classes.radioLabel}>{t('positionType')}</FormLabel>
                                <RadioGroup row value={values.positionType}
                                            onChange={(event) => onTextChange(parseInt(event.target.value),"positionType")}
                                            className={classes.radioLabel}>
                                    <FormControlLabel value={0} control={<Radio sx={{color : "primary.light", '&.Mui-checked' : {color : "secondary.main"}}}/>}
                                                      label={t('position')}/>
                                    <FormControlLabel value={1} control={<Radio sx={{color : "primary.light", '&.Mui-checked' : {color : "secondary.main"}}}/>}
                                                      label={t('positionsInterval')}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {
                            values.positionType === 0 &&
                                <TextInputContainer xs={12} required label={t('positionInRank')} value={values.position}
                                    type="number"
                                    min={1} step={1}
                                    onTextChange={(value) => onTextChange(value,"position")}
                                    error={error.position}/>
                        }
                        {
                            values.positionType === 1 && <>
                                    <TextInputContainer xs={6} label={t('minPosition')} value={values.minPosition}
                                        type="number" required
                                        onTextChange={(value) => {onTextChange(value, "minPosition")}}
                                        error={error.minPosition}/>

                                    <TextInputContainer xs={6} label={t('maxPosition')} value={values.maxPosition}
                                        type="number" required
                                        onTextChange={(value) => {onTextChange(value, "maxPosition")}}
                                        error={error.maxPosition}/>

                                    <TextInputContainer xs={6} required label={t('awardsAmount')} value={values.amount}
                                        type="number"
                                        min={1} step={1}
                                        onTextChange={(value) => onTextChange(value,"amount")}
                                        error={error.amount}/>
                            </>
                        }
                    </>
                }
                <Grid item xs={12} style={{padding: "16px"}}>
                    <RadioAwardType value={values.type} onChange={(value) => {onTextChange(value, "type")}} label={t('valueType')}/>
                </Grid>

                {
                    (values.type === 0 || values.type === 1 || values.type === 3) &&
                    <TextInputContainer xs={12} required label={values.type === 1 ? t('pointsValue') : t('euroValue')}
                                        value={values.value}
                                        type="number"
                                        min={1} step={1}
                                        onTextChange={(value) => {
                                            onTextChange(value,"value")
                                        }}
                                        error={error.value}/>
                }

                    <TextInputContainer xs={12} label={t('description')} value={values.description}
                               type="string" multiline
                               onTextChange={(value) => {onTextChange(value, "description")}}
                               error={error.description}/>

                <Grid item xs={12} style={{padding: "16px"}}>
                    <FileInput folder={"organizations/awards"} prefix={id} onRequestSave={(e) => setValues({ ...values, "imageUrl": e })} label={t('dragImage')}/>
                </Grid>
            </Grid>
        </BaseModal>
    );

}
