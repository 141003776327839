import React, {useContext, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Sidebar from "../components/layout/Sidebar";
import {useSetMenu} from "../services/MenuManager";
import {CssBaseline, Paper} from "@mui/material";
import Header from "../components/layout/Header";
import {Redirect, Route, Switch} from "react-router-dom";
import {UserManagement} from "./UserManagement";
import ProfilePage from "./Users/ProfilePage";
import SessionInfo from "./SessionManagement/SessionInfo";
import Page404 from "../components/pages/Page404";
import {OrganizationsList} from "./OrganizationsList";
import Dashboard from "./Dashboard";
import {Revolut} from "./Revolut";
import {OfflineSessionPage} from "./OfflineSessionPage";
import {resources, roles} from "../services/ability";
import NationalRanksManagement from "./NationalRanksManagement";
import {AbilityContext} from "../services/Can";
import NationalAchievementsManagement from "./NationalAchievementsManagement";
import {UserContext} from "./App";
import CustomAwards from "./CustomAwards";
import VendorManagement from "./Vendor/VendorManagement";
import CouponManagement from './CouponManagement';
import VendorRoutesAdmin from "./Vendor/VendorRoutesAdmin";
import ShopManagement from "./Vendor/Shop/ShopManagement";
import {useQueryClient} from "react-query";
import {get, ORGANIZATIONS, USERS,} from "../services/Client";
import {useSynchronizeCities} from "../services/ContentManager";
import {OrganizationUsers} from "./Dashboard/OrganizationUsers";
import Offices from "./OrganizationOffices/Offices";
import OfficeArticles from "./OrganizationOffices/OfficeArticles";
import {synchronizeUsers} from "../services/UserDataManager";
import DrinkingFountains from "./DrinkingFountains";
import WelcomeMessages from "./WelcomeMessages";
import { GeneralSettings } from './GeneralSettings/GeneralSettings';

import { RideManagement } from './RideManagement';
import { SessionManagement } from "./SessionManagement";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        maxWidth:'100vw'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function Main() {

    const ability = useContext(AbilityContext);
    const loggedUser = useContext(UserContext);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const queryClient = useQueryClient()

    useEffect(() => {
        if (ability?.can('read', resources.ALL_USERS)) {
            synchronizeUsers()
        }
    }, [ability])

    useEffect(() => {
        if (!loggedUser.organization) return;
        queryClient.prefetchQuery([USERS, {oid: loggedUser.organization}], () => get(USERS, {params: {oid: loggedUser.organization}}))
    }, [loggedUser, queryClient])

    useSynchronizeCities();
    queryClient.prefetchQuery(ORGANIZATIONS, () => get(ORGANIZATIONS))
    let menu = useSetMenu(ability, loggedUser);

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Header menuOpen={open} toggleMenu={setOpen}/>
            <Sidebar open={open} setOpen={setOpen} items={menu}/>

            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Switch>

                    <Route path={["/dashboard/:id/:filter/:actionId", "/dashboard/:id/:filter"]}>
                        <Dashboard/>
                    </Route>
                    <Redirect from="/dashboard/:id" to="/dashboard/:id/statistics"/>
                    <Route path={"/dashboard"}>
                        <OrganizationsList/>
                    </Route>
                    <Route path={"/sessions/:id"}>
                        <SessionInfo/>
                    </Route>
                    <Route path={"/profile/:uid/:filter"}>
                        <ProfilePage/>
                    </Route>
                    <Redirect from="/profile/:uid" to="/profile/:uid/enrollments"/>
                    <Redirect from="/profile" to={"/profile/" + loggedUser.uid + "/enrollments"}/>
                    <Route path={"/sessions"}>
                        <Paper style={{ padding: "2rem" }}>
                            <SessionManagement />
                        </Paper >
                    </Route>
                    {/* <Route path={"/rides"}>
                        <Paper style={{ padding: "2rem" }}>
                            <RideManagement />
                        </Paper >
                    </Route> */}
                    <Route path={"/customAwards"}>
                        <CustomAwards/>
                    </Route>
                    <Route path={"/nationalRank"}>
                        {ability.can('read', resources.NATIONALRANKS) ?
                            <NationalRanksManagement/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>
                    <Route path={"/nationalAchievements"}>
                        {ability.can('read', resources.NATIONALACHIEVEMENTS) ?
                            <NationalAchievementsManagement/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>
                    <Route path={"/drinkingFountains"}>
                        {ability.can('read', resources.DRINKINGFOUNTAINS) ?
                            <DrinkingFountains/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>
                    <Route path={"/users"}>
                        {
                            loggedUser.organization && loggedUser.userType !== roles.ADMIN ?//un admin può vedere tutti gli utenti indistintamente dall'organizzazione
                                <Paper style={{padding: "2rem"}}>
                                    <OrganizationUsers org={loggedUser.organization}  showActivationDate={true}/>
                                </Paper> :
                                <UserManagement/>
                        }
                    </Route>
                    <Route path={"/revolut"}>
                        {ability.can('read', resources.REVOLUT) ?
                            <Revolut/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>
                    <Route path={"/debug"}>
                        {ability.can('read', resources.DEBUG) ?
                            <OfflineSessionPage/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>
                    <Route path={'/vendors/:uid'}>
                        {ability.can('read', resources.VENDOR) ?
                            <VendorRoutesAdmin/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>
                    <Route path={"/vendors"}>
                        {ability.can('read', resources.VENDOR) ?
                            <VendorManagement/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>

                    <Route path={"/coupons"}>
                        {ability.can('read', resources.COUPONS) ?
                            <CouponManagement nationals/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>

                    <Route path={"/shops"}>
                        {ability.can('read', resources.SHOP) ?
                            <ShopManagement/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>

                    <Route path={"/offices"}>
                        {ability.can('read', resources.OFFICES) ?
                            <Offices/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>

                    <Route path={"/articles"}>
                        {ability.can('read', resources.OFFICES) ?
                            <OfficeArticles/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>

                    <Route path={"/welcome-messages"}>
                        {ability.can('read', resources.WELCOME_MESSAGES) ?
                            <WelcomeMessages/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>

                    <Route path={"/settings"}>
                        {ability.can('read', resources.SETTINGS) ?
                            <GeneralSettings/> :
                            <Redirect exact from="/" to="/dashboard"/>}
                    </Route>

                    <Redirect exact from="/" to="/dashboard"/>
                    <Route path={"/"}>
                        <Page404/>
                    </Route>

                </Switch>
            </main>
        </div>
    );
}
