import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { firebaseAuth, getErrorMessage } from "../../../firebase";
import { useSnackbar } from "notistack";
import { post, put, VENDORS } from "../../../services/Client";
import { validateEmailPass, validatePrivacyTerms, validateVendorAndShopData } from "../../../services/ValidationFormManager";
import { useTranslation } from "react-i18next";
import useGlobalStyles from "../../../services/useGlobalStyles";
import CircularLoading from "../../../components/CircularLoading";
import { ArrowBack } from "@mui/icons-material";
import { Box, Step, StepButton, Stepper } from '../../../../node_modules/@mui/material/index';
import { useGetCategories } from 'services/ContentManager';
import { getAddressComponents } from 'services/FormBuilderManager';
import { isEmpty } from 'services/helper';
import { urlEncode } from 'services/couponRedeemManager';
import NewVendorStep1 from './NewVendorStep1';
import NewVendorStep2 from './NewVendorStep2';
import NewVendorStep3 from './NewVendorStep3';


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        padding: theme.spacing(3),
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
        }
    },

    formControl: {
        width: '100%'
    },
    title: {
        width: '100%',
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
    }
}));

/**
 * @param uid se è presente un uid i dati dell'utente sono presi in automatico
 * @param goBack
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormNewVendor({
    errorMessages,
    data,
    setData,
    errors,
    setErrors,
    placesService,
    user,
    goBack = null
}) {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [completed, setCompleted] = useState([false, false, false]);
    const steps = [t('referentData'), t('companyData'), t('createFirstShop')];

    const { categories: allCategories, status: categoriesStatus } = useGetCategories();
    const [loadingCoordinates, setLoadingCoordinates] = useState(false);

    const handleOnChange = (text, field) => {
        setData({ ...data, [field]: text });
    };

    const handleShopChange = (text, field) => {
        setData(data => ({
            ...data,
            firstShop: {
                ...data.firstShop,
                [field]: text
            }
        }));
    };

    const renderStepContent = () => {
        switch (step) {
            case 0:
                return <NewVendorStep1
                    user={user}
                    data={data}
                    handleOnChange={handleOnChange}
                    errors={errors}
                    t={t}
                    globalClasses={globalClasses}
                />
            case 1:
                return <NewVendorStep2
                    classes={classes}
                    data={data}
                    handleOnChange={handleOnChange}
                    errors={errors}
                    t={t}
                    globalClasses={globalClasses}
                />
            case 2:
                return <NewVendorStep3
                    classes={classes}
                    data={data}
                    allCategories={allCategories}
                    setData={setData}
                    onAddressInputChange={onAddressInputChange}
                    onAddressChange={onAddressChange}
                    loadingCoordinates={loadingCoordinates}
                    errors={errors}
                    setErrors={setErrors}
                    t={t}
                    globalClasses={globalClasses}
                />
            default:
                return null;
        }
    };

    const handleStep = (step) => () => {
        setStep(step);
    };

    function save() {
        return user ? convert() : create();
    }

    function convert() {
        setLoading(true);
        let newData = { ...data };
        //----controllo
        let [errorsData, isValidData] = validateVendorAndShopData(newData);//controlli divisi per riutilizzarli nel vendor profile

        setErrors({ errorsData });
        if (!isValidData) {
            setLoading(false);
            enqueueSnackbar(errorMessages.ERROR_FORM, { variant: "error" });
            return;
        }
        //-------------
        //cancella password prima di mandare la richiesta
        newData.newPassword = null;
        newData.repeatPassword = null;

        newData.visible = true;
        newData.uid = firebaseAuth.currentUser.uid;
        put(`${VENDORS}/${user.uid}/promote`, { body: newData })
            .then(async () => {
                //---mandare mail di conferma---
                enqueueSnackbar(errorMessages.VENDOR_REGISTERED, { variant: "success" });
                history.go(0);
            })
            .catch(() => {
                enqueueSnackbar(errorMessages.CREATION_ERROR_VENDOR, { variant: "error" });
            })
    }

    function create() {
        setLoading(true);
        let newData = {
            ...data, firstShop: {
                ...data.firstShop,
                isPrimary: true,
                publicLink: urlEncode(data.firstShop.name),
                phonePrefix: data.phonePrefix,
                phone: data.phone,
                visible: true
            }
        };

        //--------controllo-----------
        let [errorsEmailPass, isValidEmailPass] = validateEmailPass(newData);
        if (errorsEmailPass.repeatPassword) {
            alert(t('passwordNotMatch'));
        }
        let [errorsData, isValidData] = validateVendorAndShopData(newData);//controlli divisi per riutilizzarli nel vendor profile

        let [errorsValidTerms, isValidTerms] = validatePrivacyTerms(newData);//controlli divisi per riutilizzarli nel vendor profile

        setErrors({ ...errorsEmailPass, ...errorsData, ...errorsValidTerms });
        if (!isValidEmailPass || !isValidData || !isValidTerms) {
            setLoading(false);
            enqueueSnackbar(errorMessages.ERROR_FORM, { variant: "error" });
            return;
        }
        //----- ------------

        firebaseAuth.createUserWithEmailAndPassword(newData.email, newData.newPassword).then(() => {
            //cancella password prima di mandare la richiesta
            newData.newPassword = null;
            newData.repeatPassword = null;

            newData.visible = true;
            newData.uid = firebaseAuth.currentUser.uid;
            post(VENDORS, { body: newData })
                .then(async () => {
                    const baseUrl = window.location.origin;
                    //---mandare mail di conferma---
                    await firebaseAuth.currentUser.sendEmailVerification({ url: baseUrl + '/emailValidated?isVendor=true' });
                    await firebaseAuth.signOut();
                    enqueueSnackbar(errorMessages.VENDOR_REGISTERED, { variant: "success" });
                    history.push('/confirmEmail');
                })
                .finally(async () => {
                    setLoading(false);
                })
                .catch(() => {
                    //se c'è un errore nel salvataggio cancello l'utente firebase
                    firebaseAuth.currentUser.delete().then(() => {
                        enqueueSnackbar(errorMessages.CREATION_ERROR_VENDOR, { variant: "error" });
                    })
                });
        }).catch((e) => {
            console.log(e);
            enqueueSnackbar(errorMessages.CREATION_ERROR_VENDOR + ' ' + getErrorMessage(e), { variant: "error" });
            setLoading(false);
        })

    }

    function onAddressInputChange(input, key) {
        //handleOnChange(null,'address');//azzera l'indirizzo nel metre viene scritto per evitare che si salvi senza aver selezionato un indirizzo trovato da google
    }

    function onAddressChange(address, placeId, key) {
        setErrors((error) => ({ ...error, address: null }));
        handleShopChange(address, key);//quando viene selezionato un address specifico allora lo salvo nello stato
        if (isEmpty(address)) {
            setErrors((error) => ({ ...error, key: errorMessages.MANDATORY }));
            setLoadingCoordinates(false);
            return;
        }
        searchCoordinates(placeId, key);
    }

    function searchCoordinates(placeId, key) {
        setLoadingCoordinates(true);
        let sessionToken = new window.google.maps.places.AutocompleteSessionToken();

        placesService.getDetails({ placeId, sessionToken }, (place) => {
            if (!place) {
                setErrors((error) => ({ ...error, [key]: t('internalServerError') }));
                return;
            }
            let addressComponents = getAddressComponents(place);
            setData(data => ({
                ...data,
                firstShop: {
                    ...data.firstShop,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                    number: addressComponents('street_number'),
                    city: addressComponents('locality') || addressComponents('political'),
                    address: addressComponents('route') /*+ ' ,' + addressComponents('country')*/
                }
            }));
            setLoadingCoordinates(false);
        });
    }

    return (
        <>
            {goBack && (
                <Button onClick={goBack} sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    marginTop: '-8px'
                }}>
                    <ArrowBack className={globalClasses.backButtonIcon} />
                </Button>
            )}
            <Box component="form" noValidate>
                <Box sx={{ alignItems: 'center' }}>
                    <Typography component="h1" variant="h5" sx={{ marginTop: 4, textAlign: 'center' }}>
                        {t('registerAsVendor')}
                    </Typography>
                </Box>

                <Stepper nonLinear activeStep={step} sx={{ mt: 4, mb: 2 }}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                {renderStepContent()}

                {/* Aggiungi il pulsante "Continua" solo se non sei sull'ultimo step */}
                <Grid container justifyContent="center" spacing={2}>
                    {step > 0 && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleStep(step - 1)}
                            disabled={loading}
                            sx={{ m: 2, borderRadius: '20px' }}
                        >
                            {t('backward')}
                        </Button>
                    )}
                    {step < steps.length - 1 && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleStep(step + 1)}
                            disabled={loading}
                            sx={{ m: 2, borderRadius: '20px' }}
                        >
                            {t('forward')}
                        </Button>
                    )}
                    {step == steps.length - 1 && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={save}
                                disabled={loading}
                                sx={{ mt: 2, mb: 2, borderRadius: '20px' }}
                            >
                                {t('register')}
                            </Button>
                            {loading && <CircularLoading />}
                        </>
                    )}
                </Grid>
            </Box>
        </>
    );
}
