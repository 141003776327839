import Grid from "@mui/material/Grid";
import {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import React,{useEffect,useState} from "react";
import {useTranslation} from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    previewTitle: {
        color: theme.palette.primary.light,
        paddingBottom: "0.5rem",
        paddingTop: "2rem",
    },
    preview: {
        color: theme.palette.primary.light,
        "white-space": "pre-line",
        maxHeight: "15vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "10px"
        },
    }
}));

export default function SendMessageModal({open, onClose, onSave, targets}){

    const {t} = useTranslation();
    let [values, setValues] = useState({});
    let [error, setError] = useState({});
    let classes = useStyles();

    useEffect(() => {
        setValues({});
        setError({});
    }, [open])

    const save = () => {
        if(!values.title) {
            setError({title : t('mandatoryField')});
            return;
        }
        onSave(values);
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1.5rem"}}>

            <TextInputContainer xs={12} label={t('title')} value={values.title}
                       type="string" error={error.title}
                       onTextChange={(value) => {onTextChange(value, "title")}}/>

            <TextInputContainer xs={12} label={t('body')} value={values.body}
                       type="string"
                       multiline={true}
                       onTextChange={(value) => {onTextChange(value, "body")}}/>

            {targets && <Grid container justifyContent={"center"}>
                <Grid item xs={8} className={classes.previewTitle}>
                    {t('alertSendNotification', {count: targets.length})}
                </Grid>
                <Grid container xs={8} className={classes.preview}>
                    {targets.map((t, i) => i+1 + ". " + t.username + ", " + t.email + "\n")}
                </Grid>
            </Grid>}

        </Grid>
    </BaseModal>
}
