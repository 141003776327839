// Import necessari
import React from "react";
import { Grid } from "@mui/material";
import TextInput from "../../../components/forms/TextInput";
import clsx from "clsx";
import { Typography } from "../../../../node_modules/@mui/material/index";

export default function NewVendorStep2({
    classes,
    data,
    handleOnChange,
    errors,
    t,
    globalClasses,
}) {
    return (
        <Grid container
            direction="row"
            alignItems="flex-start"
            className={globalClasses.marginBottom}
        >
            <Grid item xs={12} className={globalClasses.marginBottom} />

            {/*--- ragione sociale  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('businessName')}
                    type="text"
                    color={'primary'}
                    value={data.businessName || ''}
                    onTextChange={(text) => handleOnChange(text, 'businessName')}
                    error={errors.businessName || ''}
                    required
                />
            </Grid>

            {/*--- p Iva  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('vatNumber')}
                    type="text"
                    color={'primary'}
                    value={data.vatNumber || ''}
                    onTextChange={(text) => handleOnChange(text, 'vatNumber')}
                    error={errors.vatNumber || ''}
                    helperText={t('insertFirst2CharToFindCountry')}
                    required
                />
            </Grid>

            <Grid item xs={12} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <Typography className={clsx(errors.address && classes.colorRed)} xs={{ mb: 1 }}>
                    {t('legalOffice')}
                </Typography>
                {(errors.address || errors.postalCode) && <Typography variant={'caption'}
                    className={clsx(classes.colorRed, globalClasses.marginBottomSmall)}>{errors.address ?? errors.postalCode}</Typography>}
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    sx={{ mt: 1 }}>
                    <Grid item xs={7} md={9} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('address')}
                            type="text"
                            color={'primary'}
                            value={data.address || ''}
                            onTextChange={(text) => handleOnChange(text, 'address')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={5} md={3} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('houseNumber')}
                            type="text"
                            color={'primary'}
                            value={data.number || ''}
                            onTextChange={(text) => handleOnChange(text, 'number')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={7} md={7} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('city')}
                            type="text"
                            color={'primary'}
                            value={data.city || ''}
                            onTextChange={(text) => handleOnChange(text, 'city')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={5} md={2} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('postalCode')}
                            type="text"
                            color={'primary'}
                            value={data.postalCode || ''}
                            onTextChange={(text) => handleOnChange(text, 'postalCode')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className={globalClasses.marginBottom}>
                        <TextInput
                            label={t('province')}
                            type="text"
                            color={'primary'}
                            value={data.province || ''}
                            onTextChange={(text) => handleOnChange(text, 'province')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextInput
                            label={t('country')}
                            type="text"
                            color={'primary'}
                            value={data.country || ''}
                            onTextChange={(text) => handleOnChange(text, 'country')}
                            required
                            error={!!(errors.address || errors.postalCode)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* <VendorData handleOnChange={handleOnChange} vendor={data} errors={errors} hidePhone={true} /> */}
        </Grid>
    );
}
