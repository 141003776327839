import {FormControlLabel,FormLabel,Radio,RadioGroup} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
        radioLabel : {
            color : theme.palette.primary.light,
            display : 'flex',
            alignContent : "space-between",
            '&.Mui-focused' : {
                color : theme.palette.primary.light
            }
        },
        radioLabelDark : {
            color : theme.palette.secondary.main,
            display : 'flex',
            alignContent : "space-between",
            '&.Mui-focused' : {
                color : theme.palette.text.primary
            }
        }
    }
));

export default function RadioAwardType({value,onChange,label, color = "light"}) {

    const {t} = useTranslation();
    let classes = useStyles();
    const firstColor = color === "light" ? "primary.light" : "secondary.main"
    const secondColor = color === "light" ? "secondary.main" : "text.primary"

    return <FormControl component="fieldset">
        <FormLabel component="legend" sx={{color : firstColor, '&.Mui-focused' : {color : firstColor}}}>{label}</FormLabel>
        <RadioGroup row value={value} className={color === "light" ? classes.radioLabel : classes.radioLabelDark}
                    onChange={(event) => onChange(parseInt(event.target.value), "type")}>
            <FormControlLabel value={0} control={<Radio sx={{color : firstColor, '&.Mui-checked' : {color : secondColor}}}/>} label={t('moneyToBeCleared')}/>
            <FormControlLabel value={1} control={<Radio sx={{color : firstColor, '&.Mui-checked' : {color : secondColor}}}/>} label={t('points')}/>
            <FormControlLabel value={2} control={<Radio sx={{color : firstColor, '&.Mui-checked' : {color : secondColor}}}/>} label={t('toBeCollectedInMunicipality')}/>
            <FormControlLabel value={3} control={<Radio sx={{color : firstColor, '&.Mui-checked' : {color : secondColor}}}/>} label={t('toBeCollectedInShop')}/>
        </RadioGroup>
    </FormControl>
}