import { useParams } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { useGetOrganization, useGetOrganizationPartials, useGetOrganizationPartialsAll, useGetOrganizationPolylines, useGetOrganizationPolylinesAll } from "../../services/ContentManager";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MapContainer from "../../components/MapContainer";
import { HeatmapLayer, Marker, MarkerClusterer } from "@react-google-maps/api";
import { CircularProgress } from "@mui/material";
import decodePolyline from "decode-google-map-polyline";
import OrganizationAreaLayer from "../../components/mapview/OrganizationAreaLayer";
import { partialType } from "../../constants/partialType";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: "70vh",
    },
}));

export default function Heatmap({ filters, showOrganizationArea, showMarkers, children, overrideOrganization }) {

    let classes = useStyles();
    let { id } = useParams();
    let isAll = id == "all" ? true : false;
    let allEndDate = new Date(filters?.maxDate);
    allEndDate = allEndDate.setDate(allEndDate.getDate() + 1);
    let filtersAll = {
        startDate: new Date(filters?.minDate).getTime(),
        endDate: allEndDate,
        ...filters,
    };

    let { polylines, status } = useGetOrganizationPolylines(id, filters, isAll);
    let { polylines: polylinesAll, status: statusAll } = useGetOrganizationPolylinesAll(id, filtersAll, !isAll);

    let { partials: markers = [], status: markersStatus } = useGetOrganizationPartials(id, filters, isAll);
    let { partials: markersAll = [], status: markersStatusAll } = useGetOrganizationPartialsAll(id, filtersAll, !isAll);

    if (isAll && markersStatusAll && statusAll) {
        polylines = polylinesAll;
        status = statusAll;
        markers = markersAll;
        markersStatus = markersStatusAll;
    }

    let [partials, setPartials] = useState([]);
    let [toShowMarkers, setToShowMarkers] = useState([]);
    let [zoom, setZoom] = useState(12);
    let [center, setCenter] = useState();
    let { organization, statusOrganization } = useGetOrganization(id, !overrideOrganization);
    if (overrideOrganization) {
        organization = overrideOrganization;
        statusOrganization = 'success';
    }
    let organizationGeojson = organization.geojson && JSON.parse(organization.geojson)
    useEffect(() => {
        if (statusOrganization === 'success')
            setCenter(getCenterFromGeoJson());
    }, [statusOrganization])

    useEffect(() => {
        if (polylines) savePartials(polylines)
    }, [polylines])

    useEffect(() => {
        if (!showMarkers.startStop && !showMarkers.pauseResume) {
            setToShowMarkers([])
            return
        }
        if (showMarkers.startStop && showMarkers.pauseResume) {
            setToShowMarkers(markers)
            return
        }
        if (showMarkers.startStop && !showMarkers.pauseResume) {
            setToShowMarkers(markers.filter(m => partialType.START === m.type || partialType.END === m.type))
            return
        }
        if (!showMarkers.startStop && showMarkers.pauseResume) {
            setToShowMarkers(markers.filter(m => partialType.PAUSE === m.type || partialType.RESUME === m.type))
        }
    }, [showMarkers, markers])

    function getCenterFromGeoJson() {
        return organizationGeojson && organizationGeojson[0] && organizationGeojson[0][0]
    }

    const savePartials = (polylines) => {

        const partialsMap = new Map();
        let nCoordinates = 0
        let midLat = 0
        let midLng = 0
        let min = 10000000;
        let max = 0;

        polylines?.forEach(po => {
            po?.polyline?.forEach((p, i) => {
                const decodedPolyline = decodePolyline(p);
                decodedPolyline.forEach((dp, di) => {
                    if (di === 0 || di === 1) {
                        midLat += dp.lat
                        midLng += dp.lng
                        nCoordinates++
                    }

                    let location = Math.round(dp.lat * 10000) / 10000 + ":" + Math.round(dp.lng * 10000) / 10000;
                    let w = partialsMap.get(location) + 1 || 1
                    partialsMap.set(location, w);
                    min = Math.min(min, w)
                    max = Math.max(max, w)

                })
            })
        })

        let newPartials = []
        partialsMap.forEach((value, key) => {
            let co = key.split(":")
            newPartials.push({ location: new window.google.maps.LatLng(co[0], co[1]), weight: (value - min) / (max - min) * 50 })
        })

        setCenter(nCoordinates !== 0 ? new window.google.maps.LatLng(midLat / nCoordinates, midLng / nCoordinates) : getCenterFromGeoJson() || new window.google.maps.LatLng(41.9028, 12.4964))
        setPartials(newPartials)
    }

    //if(status === "loading" ) return <Grid container style={{padding: 30}}><CircularProgress/></Grid>;

    const getHeatmapOptions = () => {
        let radius = 4;
        let maxIntensity = 25;
        let opacity = 0.6;
        let dissipating = true;

        if (zoom === 13) {
            radius = 0.0008
            dissipating = false
        }
        if (zoom > 13) {
            maxIntensity = 20
            radius = 0.0004
            dissipating = false
        }
        if (zoom > 15) {
            maxIntensity = 15
            radius = 0.0002
        }
        return { maxIntensity, radius, opacity, dissipating }
    }

    const getMinimumClusterSize = () => {
        if (zoom >= 18) return 2;
        if (zoom >= 17) return 5;
        if (zoom >= 15) return 15;
        if (zoom >= 13) return 45;
    }

    return <Grid container className={classes.root}>
        {
            children
        }
        {
            status === 'loading' ? <Grid container style={{ padding: 30 }}><CircularProgress /></Grid>
                :
                <MapContainer center={center} zoom={partials.length !== 0 ? 12 : 13} onZoomChanged={setZoom} maxZoom={18}>

                    <HeatmapLayer data={partials} options={getHeatmapOptions()} />

                    {toShowMarkers.length > 0 && markers && markers.length !== 0 &&
                        <MarkerClusterer maxZoom={18} minimumClusterSize={getMinimumClusterSize()}>
                            {(clusterer) =>
                                toShowMarkers.map((marker, index) =>
                                    <Marker key={index} position={new window.google.maps.LatLng(marker.lat, marker.lng)}
                                        clusterer={clusterer} opacity={0.0} />
                                )
                            }
                        </MarkerClusterer>
                    }

                    {
                        showOrganizationArea && <OrganizationAreaLayer organizationId={id} overrideOrganization={overrideOrganization} />
                    }

                </MapContainer>
        }
    </Grid>

}
